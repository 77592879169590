
  export const countOccurrences = (arr, val) => arr.reduce((a, v) => (v.content.includes(val) ? a + 1 : a), 0);

  export const getCountContentType = (lesson, entity)=> {    
    let countVideos = 0; 
    lesson.forEach((lesson)=> {

        if(lesson.id=="lesson" && lesson.content.includes(entity)) {
            countVideos += 1;
        }

        else if (lesson.id=="lesson_container") {
            countVideos += countOccurrences(lesson.lessons,entity);
        }
    })
    return countVideos;
  }


export const sumDuration = (arr, val) => arr.reduce((a, v) => (v.content.includes(val) ? a + parseInt(v.duration) : a), 0);

export const getDuration = (lesson) =>  {
    let duration = 0;
    lesson.forEach((lesson)=>{
        if(lesson.id=="lesson" && lesson.content.includes("video")) {
            duration += parseInt(lesson.duration) ;
        }
        else if (lesson.id=="lesson_container") {   
            duration +=  sumDuration(lesson.lessons,"video");
        }
    })
    return duration;
 }
