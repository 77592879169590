import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "gatsby-link";
import Img from "gatsby-image"
import VideoPlayer from "../videoplayer/videoplayer";

import { GrPlayFill } from "react-icons/all"

import * as styles from "./hero.module.scss"
import * as btnStyles from "../buttons/buttons.module.scss"
import * as indexStyles from "../../scss/indexPage.module.scss"
import { useDispatch } from "react-redux"


const Hero = ({ loggedIn }) => {



    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)


    const initShapeConfig = () => {
        const shapeFistOffsetTop = shapeFirst.current.offsetTop
        const shapeSecondOffsetTop = shapeSecond.current.offsetTop
        window.addEventListener("scroll", () => {
            if (shapeFirst.current) {
                shapeFirst.current.style.top =
                    shapeFistOffsetTop + window.scrollY / 3 + "px"
                shapeSecond.current.style.top =
                    shapeSecondOffsetTop - window.scrollY / 3 + "px"
            } else {
                window.removeEventListener("scroll", () => { })
            }
        })
    }



    useEffect(() => {
        initShapeConfig()
    })

    const dispatch = useDispatch()

    const {
        fileName: {
            childImageSharp: { fluid },
        },
    } = useStaticQuery(graphql`
    query {
      fileName: file(name: { eq: "IMG-20210308-WA0010@2x" }) {
        childImageSharp {
          fluid(maxWidth: 1736, maxHeight: 1166) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    return (
        <div className={styles.hero}>
            <div className={`clearfix container ${styles.container}`}>


                <div className={styles.intro}>
                    <h1>
                        <div>In 7 Tagen erfolgreich entgiften!</div>
                        <div>Ich begleite dich. </div>
                    </h1>
                    {/* <div className={`${styles.cover} ${styles.hideDesktop}`} style={{marginTop: 30}}> */}
                    <div className={`${styles.cover}`} style={{ marginTop: 30 }}>


                        <VideoPlayer
                            hide="desktop"
                            url="https://player.vimeo.com/video/562707404"
                            play={true}
                            secondsProgressInLocalStorage={false}
                        />

                    </div>
                    <ul>
                        <li>- Ganzheitlicher Detox Online-Kurs in 60 Videos</li>
                        <li>- Tägliche Menüpläne, Checklisten und Anleitungen (auch als PDF)</li>
                        <li>- Schritt für Schritt-Anleitung, perfekt zuhause durchführbar</li>
                        <li>- Rezeptbuch mit leckerem Essen nach den Detox-Richtlinien</li>
                        <li>- BONUS: Emotionales Detox-Programm</li>
                    </ul>
                    <div
                        className={`${btnStyles.buttonGroup} ${indexStyles.buttonGroup}`}
                    >
                        <Link to="/modules" className={btnStyles.button}>
                            <span style={{ color: "white !important" }}>
                                {loggedIn ? "Zu den Videos" : "Kursvorschau ansehen"}
                            </span>
                        </Link>

                        {!loggedIn &&
                            <Link
                                to="/checkout"
                                className={`${btnStyles.button} ${btnStyles.buttonBorder}`}
                            >
                                Jetzt entgiften
                            </Link>}
                    </div>
                </div>


                <div ref={shapeFirst} className={styles.shapeFirst}></div>
                <div ref={shapeSecond} className={styles.shapeSecond}></div>
                {/* <div className={`${styles.cover} ${styles.hideMobile}`}> */}
                <div className={`${styles.cover}`}>

                    <VideoPlayer
                        hide="mobile"
                        url="https://player.vimeo.com/video/562707404"
                        play={true}
                        secondsProgressInLocalStorage={false}
                    />

                </div>
            </div>
        </div>
    )
}

export default Hero
