// extracted by mini-css-extract-plugin
export var hero = "hero-module--hero--1Ngwp";
export var container = "hero-module--container--zMPlH";
export var intro = "hero-module--intro--10iGf";
export var shapeFirst = "hero-module--shape-first--3E2Ko";
export var shapeSecond = "hero-module--shape-second--4tYjQ";
export var play = "hero-module--play--rphRG";
export var hideMobile = "hero-module--hideMobile--3-cFw";
export var hideDesktop = "hero-module--hideDesktop--3FiTN";
export var cover = "hero-module--cover--n7HFD";
export var imageWrapper = "hero-module--imageWrapper--31kRl";