import React, { useEffect, useState } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Preview from "../components/preview"
import ListObjects from "../components/listObjects"
import Checklist from "../components/checklist"
import Subscription from "../components/subscription"
import TestimonialsCarousel from "../components/carousel/testemonials"
import * as indexStyles from "../scss/indexPage.module.scss"
import { Fragment } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { countOccurrences, getCountContentType, sumDuration, getDuration } from "../services/courseOverviewFunctions";
import getTestimonials from "../services/testimonials"
import isLoggedIn from "../services/auth"
import clearLocalStorage from "../services/clearLocalStorage"
import 'bootstrap/dist/css/bootstrap.min.css';


const IndexPage = () => {

    const graphResult = useStaticQuery(graphql`
    {
        wpKurs(slug: {eq: "detox-me"}) {
          id
          title
          renderContent
        }
      }
  `)


    const [loggedIn, setLoggedIn] = useState(false);

    useEffect(() => {
        isLoggedIn().then(loggedIn => {
            setLoggedIn(loggedIn)
            if (!loggedIn) {
                clearLocalStorage();
            }
        });
    }, [])

    const courseData = JSON.parse(graphResult.wpKurs.renderContent)

    let transformedCourseData = courseData.map((element) => {
        if (element.id == "moduless") {
            return {
                title: element.headline,
                countVideos: getCountContentType(element.lessons, "video"),
                countPDF: getCountContentType(element.lessons, "file"),
                duration: getDuration(element.lessons)
            }
        }
        else {
            return null;
        }
    }
    )


    return (
        <Fragment>
            <Layout>
                <SEO
                    title="Online Entgiftungskurs"
                    description = "Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
                    keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                    author= "spectory"
                />
                <Hero loggedIn={loggedIn} />
                <div className="container">
                    <div className={indexStyles.marginTop}>
                        <Preview
                            title="Warum auch du unbedingt entgiften solltest"
                            content="Unser Leben mit Fertigprodukten, Konservierungsstoffen, Stress und Elektrosmog belastet unseren Organismus mit Giften. Durch eine Detox Kur kann das körperliche und geistige Wohlbefinden gesteigert werden, da der Körper unterstützt wird, die gesammelten Toxine optimal auszuscheiden. "
                            link="/modules"
                            linkType="line"
                            linkTitle="Blick in den Kurs"
                            sourceType="video"
                            source={{
                                image:
                                    "https://detoxme.at/wp/wp-content/uploads/2021/05/Ubersicht-Modul-1.1-Vorstellung.jpg",
                                vimeo: "562705288",
                            }}
                            sourcePosition="right"
                            shapeStyles={{ top: 0, right: 0, width: "83%" }}
                        />
                    </div>
                    <div className={indexStyles.marginTop}>
                        <Preview
                            title="Entgiften und mehr als genug essen"
                            content="Ich will mit dem Mythos aufräumen, dass Entgiften heißt zu fasten und zu verzichten. Entgiften bedeutet satt sein, Wohlbefinden, Kraft und Lebensfreude und auch raffiniertes und nicht eintöniges Essen. Wir müssen dem Körper aber das Richtige geben, damit er seine Arbeit leisten kann."
                            link={loggedIn ? "/modules" : "/checkout"}
                            linkType="button"
                            linkTitle={loggedIn ? "Zu den Videos" : "Starte mit meinem Kurs"}
                            sourceType="image"
                            source={{
                                image:
                                    "https://detoxme.at/wp/wp-content/uploads/2021/06/Startseite_Bild_Essen.png",
                            }}
                            sourcePosition="left"
                            shapeStyles={{
                                height: "90%",
                                width: "80%",
                                top: "0",
                                bottom: "0",
                                right: "0",
                                left: "0",
                                marginLeft: "auto",
                                marginRight: "auto",
                            }}
                        />
                    </div>
                    <div className={indexStyles.marginTop}>
                        <ListObjects
                            title="Ein Überblick über alle Inhalte"
                            objects={transformedCourseData}
                        />
                    </div>
                    <div className={indexStyles.marginTop}>
                        <TestimonialsCarousel
                            title="Das sagen Kursteilnehmer"
                            items={getTestimonials()}
                        />
                    </div>
                    <div className={indexStyles.marginTop}>
                        <Checklist />
                    </div>
                    <div className={`${indexStyles.marginBottom} ${indexStyles.marginTop} `}>
                        <Preview
                            title="Lerne mich kennen."
                            content="Hallo, mein Name ist Claudia Koch! Ich bin ganzheitliche Therapeutin und arbeite als zertifizierte, professionelle Kinesiologin und internationale Pranatherapeutin. Zu meinem Klientel zählen Manager, Künstler, Therapeuten, Ärzte, genauso wie Studenten und Schüler. Ich unterstützte sie dabei, körperliche Gesundheitsprobleme zu überwinden, emotionale Belastungen und Traumata zu heilen, emotionalen Stress zu reduzieren."
                            link={loggedIn ? "/modules" : "/checkout"}
                            linkType="button"
                            linkTitle={loggedIn ? "Zu den Videos" : "Starte deinen Detox-Kurs jetzt!"}
                            sourceType="image"
                            source={{
                                image:
                                    "https://detoxme.at/wp/wp-content/uploads/2021/03/IMG-20210308-WA0011.jpg",
                            }}
                            sourcePosition="left"
                            sourceStyles={{ width: "77%" }}
                            shapeStyles={{
                                height: "100%",
                                width: "60%",
                                bottom: 0,
                                right: "10%",
                            }}
                        />
                    </div>
                </div>
                <Subscription />
            </Layout>
        </Fragment>
    )
}


export default IndexPage
