import React from "react"

import * as styles from "./checklist.module.scss"

import CheckmarkIcon from "./icon_checkmark.svg"

const Checklist = () => {
  return (
    <div className={styles.checklist}>
      <h2 style={{ margin: "20px 0" }} className="heading">
        Wann macht Entgiftung Sinn?
      </h2>
      <p className={styles.purposeDetox} >
      Unser modernes Leben mit Fertigprodukten, Pestiziden, Konservierungsstoffen, Elektrosmog, Stress und Luftverschmutzung belastet unseren Organismus mit Toxinen. 
      Es ist also nicht die Frage ob, sondern wie sehr wir vergiftet sind! 
      <br/>
      Deshalb benötigt JEDER Mensch Phasen der Entgiftung um gesund zu bleiben.
      </p>
      <div className={styles.subHeading}>
        Folgende Symptome erfahren Erleichterung durch mein Entgiftungsprogramm:
      </div>
      <div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Kopfweh, Migräne
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Allergien
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Ekzeme, vorzeitiges Altern, Dermatitis
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} /> Heißhungerattacken
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Hohe Cholesterinwerte
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Stimmungsschwankungen
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Verstopfung, Durchfall
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} /> PMS, Hitzewallungen
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Blähungen
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} /> Gereiztheit{" "}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Ständiges Rülpsen
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Müdigkeit, Erschöpfung
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Geschwollene Beine, Gelenke oder Hände
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} /> Gelenks und
            Muskelschmerzen.
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Immer wiederkehrende Infekte
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Hoher Blutdruck
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            grippeähnliche Symptome
          </div>
          <div className={styles.item}>
            <CheckmarkIcon className={styles.icon} />
            Begleitend zu Gewichtsreduzierung
          </div>
        </div>
      </div>
    </div>
  )
}

export default Checklist
